import axios from '@axios'

import qs from 'qs'

const prefixPath = '/api/order'
export default {

  getPages(data) {
    return axios.post(`${prefixPath}/getPages`, data)
  },

  toggleOrderStatus(orderNo, orderStatus, createdBy) {
    return axios.post(`${prefixPath}/toggleOrderStatus`, qs.stringify({
      orderNo,
      orderStatus,
      createdBy,
    }))
  },

  updateOrderMemo(orderNo, orderMemo) {
    return axios.post(`${prefixPath}/updateOrderMemo`, qs.stringify({
      orderNo,
      orderMemo,
    }))
  },

  getOrderDetail(orderNo) {
    return axios.get(`${prefixPath}/getOrderDetail`, {
      params: {
        orderNo,
      },
    })
  },

  delete(orderNo) {
    return axios.post(`${prefixPath}/delete`, qs.stringify({
      orderNo,
    }))
  },

  updateOrderProductQuantity(id, quantity) {
    return axios.post(`${prefixPath}/updateOrderProductQuantity`, qs.stringify({
      id,
      quantity,
    }))
  },

  updateOrderProductPrice(id, settlementPrice) {
    return axios.post(`${prefixPath}/updateOrderProductPrice`, qs.stringify({
      id,
      settlementPrice,
    }))
  },
  updateOrderPrice(orderNo, orderPrice) {
    return axios.post(`${prefixPath}/updateOrderPrice`, qs.stringify({
      orderNo,
      orderPrice,
    }))
  },

  exportOrder(data) {
    return axios.post(`${prefixPath}/excel/export`, data, {
      responseType: 'blob',
    })
  },
}
