import axios from '@axios'

import qs from 'qs'

const prefixPath = '/api/product/stock'
export default {

  getPages(data) {
    return axios.post(`${prefixPath}/getPages`, data)
  },

  addWarehouseRecord(data) {
    return axios.post(`${prefixPath}/addWarehouseRecord`, data)
  },

  getRecordDetail(data) {
    return axios.post(`${prefixPath}/getDetail`, data)
  },

  loadData(data) {
    return axios.post(`${prefixPath}/loadData`, data)
  },

  toggleStatus(stockId, status, handleUserId) {
    return axios.post(`${prefixPath}/toggleStatus`, qs.stringify({
      stockId,
      status,
      handleUserId,
    }))
  },

  /**
   * 增加出入库打印次数
   * @param {*} stockId //单号id
   */
  addStockPrintCount(stockId) {
    return axios.post(`${prefixPath}/addStockPrintCount`, qs.stringify({
      stockId,
    }))
  },

  modifyDetailUnit(id, unitId, model) {
    return axios.post(`${prefixPath}/modifyDetailUnit`, qs.stringify({
      id,
      unitId,
      model,
    }))
  },

  /**
   * 根据时间段查询出入库的统计数量
   * @param {*} startTime;
   * @param {*} endTime;
   * @returns ;
   */
  getStockCountByDate(startTime, endTime) {
    return axios.get(`${prefixPath}/getStockCountByDate`, {
      params: {
        startTime,
        endTime,
      },
    })
  },

}
