<template>
  <v-card v-if="$can('product_stock_list', 'stock')" id="holiday-common">
    <v-form ref="searchForm" @submit.prevent="toSearch()">
      <v-card-title>
        <v-row>
          <v-col md="12">
            <p class="text-h6">出入库管理</p>
          </v-col>
          <v-col class="d-flex flex-row-reverse pb-0" md="12">
            <!-- 新增出入库类型， 靠右显示 -->
            <div v-if="$can('product_stock_out', 'stock')" class="d-flex align-center">
              <v-btn color="primary" @click="editItem(0)"> 新建配货单 </v-btn>
            </div>
            <div v-if="$can('product_stock_in', 'stock')" class="d-flex align-center">
              <v-btn color="primary" class="me-3" @click="editItem(1)"> 新增入库单 </v-btn>
            </div>

            <div class="d-flex align-center">
              <v-btn class="me-3" @click="resetSearch()">
                <span>重置</span>
              </v-btn>
            </div>

            <div class="d-flex align-center">
              <v-btn color="secondary" class="me-3" type="submit">
                <v-icon size="18" class="me-1">
                  {{ icons.mdiMagnify }}
                </v-icon>
                <span>查询</span>
              </v-btn>
            </div>

            <div class="d-flex align-center pt-6 mr-3 pb-0">
              <v-text-field v-model="search.id" outlined dense label="请输入查询内容"></v-text-field>
            </div>

            <div class="d-flex align-center pt-6 pb-0">
              <DatePick v-model="search.endTime" :hide="false" label="结束时间"></DatePick>
            </div>
            <div class="d-flex align-center">
              <span class="text-subtitle-2">至</span>
            </div>

            <div class="d-flex align-center pt-6 pb-0">
              <DatePick v-model="search.startTime" :hide="false" label="开始时间"></DatePick>
            </div>

            <div class="d-flex align-center pt-6 pb-0">
              <v-select
                id="id"
                v-model="search.typeId"
                outlined
                dense
                label="类型"
                item-text="name"
                item-value="id"
                :items="stockTypes"
              ></v-select>
            </div>
            <!-- <div
              class="d-flex align-center "
            >
              <v-btn
                color="primary"
                class="me-3"
                @click="toSaleList()"
              >
                <span>查看销售配货记录</span>
              </v-btn>
            </div> -->
          </v-col>
        </v-row>
      </v-card-title>
    </v-form>
    <v-card-text>
      <v-row>
        <v-col md="12">
          <v-tabs v-model="currentTab" style="box-shadow: none !important" @change="reloadTab">
            <v-tab>待审核({{ stockCount.waitConfirmCount }})</v-tab>
            <!-- <v-tab>已通过({{ stockCount.confirmedCount }})</v-tab> -->
            <v-tab>待处理({{ stockCount.waitOutStockCount }})</v-tab>
            <v-tab>已完成({{ stockCount.confirmedCount }})</v-tab>
            <v-tab>全部({{ stockCount.allCount }})</v-tab>
          </v-tabs>
        </v-col>

        <v-col>
          <v-data-table
            :footer-props="{
              itemsPerPageAllText: '全部',
              itemsPerPageText: '每页显示 ',
              showCurrentPage: true,
            }"
            disable-filtering
            disable-sort
            :headers="getHeader()"
            :items="stockRecords"
            :page="pages.page"
            :loading="loadings.tableloading"
            loading-text="数据加载中..."
            :items-per-page="pages.itemsPerPage"
            :server-items-length="pages.serverItemsLength"
            no-data-text="无数据"
            @pagination="loadPage"
            @update:options="updateOptions"
          >
            <template #[`item.status`]="{ item }">
              <v-chip :color="getOrderStatusColor(item.status)" small class="font-weight-semibold text-capitalize">
                {{ getOrderStatusByValue(item.status) }}
              </v-chip>
            </template>
            <template #[`item.printCount`]="{ item }">
              <template v-if="item.printCount">
                <v-badge :content="item.printCount" inline color="info">
                  <v-chip color="success" small class="font-weight-semibold text-capitalize"> 已打印 </v-chip>
                </v-badge>
              </template>
              <template v-else>
                <v-chip color="danger" small class="font-weight-semibold text-capitalize"> 未打印 </v-chip>
              </template>
            </template>
            <template #[`item.receiverinfo`]="{ item }">
              <span>{{
                !!item.sourceOrderNo && $can('stock_order', 'stock') ? item.receiverName : item.clientName
              }}</span>
            </template>

            <template #[`item.sourceOrderNo`]="{ item }">
              <a
                v-if="item.sourceOrderNo != null && $can('stock_order', 'stock')"
                href="javascript:void(0)"
                @click="toOrder(item.sourceOrderNo)"
              >
                <span>{{ item.sourceOrderNo }}</span>
              </a>
              <a
                v-else-if="item.sourceMaterialId != null && $can('stock_bill', 'stock')"
                href="javascript:void(0)"
                @click="toBill(item.sourceMaterialId)"
              >
                <span>{{ item.sourceMaterialId }}</span>
              </a>
            </template>
            <!-- action -->
            <template #[`item.actions`]="{ item }">
              <v-btn
                v-if="$can('product_stock_detail', 'stock')"
                text
                dense
                x-small
                color="primary"
                @click.stop="showDetail(item.id)"
              >
                查看详情
              </v-btn>
              <span v-if="$can('stock_modify_status', 'stock')">
                <!-- 生成按钮 如果status为2 那么按钮就是设为待确认，如果status为3 那么按钮就是设为已收款 如果status为5 那么按钮就是设为已发货 如果status为0 那么他就有 确认按钮 和取消按钮 -->
                <v-btn v-if="item.status === 0" text dense x-small color="primary" @click="toggleStatus(item.id, 4)">
                  通过
                </v-btn>
                <v-btn v-if="item.status === 0" text dense x-small color="primary" @click="toggleStatus(item.id, 2)">
                  拒绝
                </v-btn>
                <v-btn
                  v-if="item.status === 3 && item.sourceOrderNo"
                  text
                  dense
                  x-small
                  color="primary"
                  @click="toggleStatus(item.id, 3)"
                >
                  订单发货
                </v-btn>
                <v-btn
                  v-if="item.status === 3 && item.sourceOrderNo"
                  text
                  dense
                  x-small
                  color="primary"
                  @click="openCancelOrderDialog(item,1)"
                >
                  取消订单
                </v-btn>
                <v-btn
                  v-if="item.status === 3 && !item.sourceOrderNo"
                  text
                  dense
                  x-small
                  color="primary"
                  @click="toggleStatus(item.id, 1)"
                >
                  确认操作
                </v-btn>
              </span>
            </template>
            <!-- 使用 v-skeleton-loader 来自定义加载动画 -->
            <template v-slot:loading>
              <v-skeleton-loader type="circle" animation="pulse" color="primary"></v-skeleton-loader>
            </template>
          </v-data-table>
          <div style="position: absolute; right: 260px; bottom: 25px">
            <div style="display: flex; flex-wrap: wrap">
              <span style="padding: 7px; font-size: 13px">总页数：{{ pages.pages }}</span>
              <span style="padding: 7px; font-size: 13px">前往</span>
              <div style="width: 45px">
                <v-text-field
                  v-model="toPage"
                  type="number"
                  hide-details
                  dense
                  min="1"
                  @change="changePage"
                ></v-text-field>
              </div>
              <span style="padding: 7px; font-size: 13px">页</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-navigation-drawer
      v-model="editDialog"
      temporary
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? '85%' : '100%'"
      app
    >
      <v-card height="100%">
        <div class="drawer-header d-flex align-center">
          <span class="font-weight-semibold text-h6">新建{{ model === 0 ? '配货单' : '入库单' }}</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="mr-5" :disabled="commitFlag" :loading="commitFlag" @click="handleFormSubmit()">
            保存
          </v-btn>
          <v-btn icon small @click="editDialog = false">
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </div>

        <v-card-text style="background-color: #f4f5fa; height: 90%; padding-top: 20px" class="overflow-auto">
          <v-card tile>
            <v-card-title>
              <p class="text-h6">基本信息</p>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="1"> *单据类型 </v-col>
                <v-col v-if="model === 0">
                  <v-btn-toggle v-model="choiceOutType" mandatory color="primary">
                    <template v-for="(item, index) in outSidestockTypes">
                      <v-btn v-if="item.id !== 2" :key="index" style="margin-top: -6px" :value="item">
                        {{ item.name }}
                      </v-btn>
                    </template>
                  </v-btn-toggle>
                </v-col>
                <v-col v-else>
                  <v-btn-toggle v-model="choiceInType" mandatory color="primary">
                    <v-btn
                      v-for="(item, index) in inSidestockTypes"
                      :key="index"
                      style="margin-top: -6px"
                      :value="item"
                    >
                      {{ item.name }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="model === 0" cols="3">
                  <v-text-field v-model="baseInfo.client" label="客户" dense outlined clearable></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="baseInfo.receiverAddrDetail"
                    label="发往物流"
                    dense
                    outlined
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-n5 mb-n5">
                <v-col>
                  <v-textarea v-model="baseInfo.remark" rows="2" outlined label="备注" dense clearable></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card v-if="$can('product_stock_in_out_page', 'stock')" class="mt-10">
            <v-card-title>
              <p class="text-h6">
                {{ model === 0 ? '配货单详情' : '入库单详情' }}
              </p>
            </v-card-title>
            <v-card-text>
              <v-form ref="searchSkuForm">
                <v-row>
                  <v-col class="d-flex flex-row-reverse pb-0">
                    <div class="d-flex align-center">
                      <v-btn class="me-3" @click="resetSkuSearch()">
                        <span>重置</span>
                      </v-btn>
                    </div>

                    <div class="d-flex align-center">
                      <v-btn color="secondary" class="me-3" @click="toSkuSearch()">
                        <v-icon size="18" class="me-1">
                          {{ icons.mdiMagnify }}
                        </v-icon>
                        <span>查询</span>
                      </v-btn>
                    </div>

                    <div class="d-flex align-center pt-6 mr-5 pb-0">
                      <v-text-field v-model="skuSearch.name" outlined dense label="搜索商品"></v-text-field>
                    </div>

                    <div class="d-flex align-center pt-6 mr-5 pb-0">
                      <v-select
                        id="id"
                        v-model="skuSearch.saleType"
                        outlined
                        dense
                        label="销售类型"
                        item-text="name"
                        item-value="value"
                        :items="saleTypes"
                      ></v-select>
                    </div>
                    <div class="d-flex align-center pt-6 mr-5 pb-0">
                      <v-select
                        id="id"
                        v-model="skuSearch.categoryId"
                        outlined
                        dense
                        label="所属分类"
                        item-text="name"
                        item-value="id"
                        :items="categorys"
                      ></v-select>
                    </div>
                    <div class="d-flex align-center pt-6 mr-5 pb-0">
                      <v-select
                        id="id"
                        v-model="skuSearch.warehouseId"
                        outlined
                        dense
                        label="所属仓库"
                        item-text="name"
                        item-value="id"
                        :items="warehouses"
                      ></v-select>
                    </div>
                    <div class="d-flex align-center pt-6 mr-5 pb-0">
                      <v-select
                        v-model="skuSearch.selectOrderType"
                        :items="orderTypes"
                        label="排序方式"
                        dense
                        outlined
                      ></v-select>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
              <v-row>
                <v-col md="12">
                  <v-tabs v-model="skuTab" style="box-shadow: none !important">
                    <v-tab>本次{{ model === 0 ? '配货' : '入库' }} ({{ recordQuantity }})</v-tab>
                    <v-tab>全部({{ allQuantity }})</v-tab>
                  </v-tabs>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-tabs-items v-model="skuTab">
                    <v-tab-item>
                      <v-data-table
                        :footer-props="{
                          itemsPerPageAllText: '全部',
                          itemsPerPageText: '每页显示 ',
                          showCurrentPage: true,
                        }"
                        disable-filtering
                        disable-sort
                        :headers="getSkuHeader()"
                        no-data-text="无数据"
                        :items="records"
                      >
                        <template #[`item.productName`]="{ item }">
                          <v-row>
                            <v-col cols="auto">
                              <v-avatar size="33" @click.stop="previewImg(item.skuProductImg)">
                                <img :src="item.skuProductImg || require('@/assets/images/white.jpeg')" alt="John" />
                              </v-avatar>
                            </v-col>
                            <v-col class="pl-0">
                              <span class="text-subtitle-2">{{ item.productName }} </span><br />
                              <span v-if="!item.isSingle" class="text-caption">{{ item.title }}</span>
                            </v-col>
                          </v-row>
                        </template>
                        <template #[`item.categoryName`]="{ item }">
                          <v-row>
                            <v-col class="pl-0">
                              <span class="text-subtitle-2">{{ item.categoryName }} </span><br />
                              <span class="text-caption">{{ item.warehouseName }}</span>
                            </v-col>
                          </v-row>
                        </template>
                        <template #[`item.stockNum`]="{ item }">
                          <span>{{ convertToBoxAndRemainder(item.stockNum, item) }}</span>
                        </template>
                        <template #[`item.totalQuantity`]="{ item }">
                          <span v-if="model === 0" style="color: red">{{
                            item.totalQuantity ? convertToBoxAndRemainder(item.totalQuantity, item) : ''
                          }}</span>
                          <span v-else style="color: rgb(95, 157, 3)">{{
                            item.totalQuantity ? convertToBoxAndRemainder(item.totalQuantity, item) : ''
                          }}</span>
                        </template>

                        <template #[`item.unit`]="{ item }">
                          <v-select
                            id="unitId"
                            v-model="item.unitId"
                            dense
                            disabled
                            style="width: 70px"
                            hide-details
                            item-text="unitName"
                            item-value="id"
                            :items="item.unitConversion"
                          ></v-select>
                        </template>
                      </v-data-table>
                    </v-tab-item>
                    <v-tab-item>
                      <v-data-table
                        :footer-props="{
                          itemsPerPageAllText: '全部',
                          itemsPerPageText: '每页显示 ',
                          showCurrentPage: true,
                        }"
                        disable-filtering
                        disable-sort
                        :headers="getSkuHeader()"
                        :items="skus"
                        :page="skuPages.page"
                        :items-per-page="skuPages.itemsPerPage"
                        :server-items-length="skuPages.serverItemsLength"
                        no-data-text="无数据"
                        @pagination="loadSkuPage"
                      >
                        <template #[`item.productName`]="{ item }">
                          <v-row>
                            <v-col cols="auto">
                              <v-avatar size="33" @click.stop="previewImg(item.skuProductImg)">
                                <img :src="item.skuProductImg || require('@/assets/images/white.jpeg')" alt="John" />
                              </v-avatar>
                            </v-col>
                            <v-col class="pl-0">
                              <span class="text-subtitle-2">{{ item.productName }} </span><br />
                              <span v-if="!item.isSingle" class="text-caption">{{ item.title }}</span>
                            </v-col>
                          </v-row>
                        </template>
                        <template #[`item.categoryName`]="{ item }">
                          <v-row>
                            <v-col class="pl-0">
                              <span class="text-subtitle-2">{{ item.categoryName }} </span><br />
                              <span class="text-caption">{{ item.warehouseName }}</span>
                            </v-col>
                          </v-row>
                        </template>

                        <template #[`item.quantity`]="{ item }">
                          <v-text-field
                            v-model="item.quantity"
                            style="width: 150px"
                            type="number"
                            placeholder="输入数量"
                            dense
                            hide-details
                            min="0"
                            @change="addRecord(item)"
                          ></v-text-field>
                        </template>
                        <template #[`item.stockNum`]="{ item }">
                          <span>{{ convertToBoxAndRemainder(item.stockNum, item) }}</span>
                        </template>
                        <template #[`item.totalQuantity`]="{ item }">
                          <span v-if="model === 0" style="color: red">{{
                            item.totalQuantity ? convertToBoxAndRemainder(item.totalQuantity, item) : ''
                          }}</span>
                          <span v-else style="color: rgb(95, 157, 3)">{{
                            item.totalQuantity ? convertToBoxAndRemainder(item.totalQuantity, item) : ''
                          }}</span>
                        </template>
                        <template #[`item.unit`]="{ item }">
                          <v-select
                            id="unitId"
                            v-model="item.unitId"
                            dense
                            style="width: 70px"
                            hide-details
                            item-text="unitName"
                            item-value="id"
                            :items="item.unitConversion"
                            @change="changeUnit(item)"
                          ></v-select>
                        </template>
                      </v-data-table>
                      <div style="position: absolute; right: 260px; bottom: 7px">
                        <div style="display: flex; flex-wrap: wrap">
                          <span style="padding: 7px; font-size: 13px">总页数：{{ skuPages.pages }}</span>
                          <span style="padding: 7px; font-size: 13px">前往</span>
                          <div style="width: 45px">
                            <v-text-field
                              v-model="toSkuPage"
                              type="number"
                              hide-details
                              dense
                              min="1"
                              @change="changeSkuPage"
                            ></v-text-field>
                          </div>
                          <span style="padding: 7px; font-size: 13px">页</span>
                        </div>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
      <v-dialog v-model="previewImgDialog" max-width="500">
        <v-card>
          <v-card-text>
            <v-img :src="previewImgUrl || require('@/assets/images/white.jpeg')" max-width="500"></v-img>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="recordDetailDialog"
      temporary
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? '70%' : '100%'"
      app
    >
      <v-card height="100%">
        <div class="drawer-header d-flex align-center">
          <span class="font-weight-semibold text-base text-h6">{{
            recordDetail.baseInfo.isIn ? '入库单' : '配货单'
          }}</span>

          <v-btn
            icon
            small
            :disabled="recordDetail.baseInfo.suffixWarehouseId == null"
            @click="showDetail(recordDetail.baseInfo.suffixWarehouseId)"
          >
            <v-icon size="22">
              {{ icons.mdiChevronLeft }}
            </v-icon>
          </v-btn>
          &nbsp;&nbsp;
          <v-btn
            icon
            small
            :disabled="recordDetail.baseInfo.prefixWarehouseId == null"
            @click="showDetail(recordDetail.baseInfo.prefixWarehouseId)"
          >
            <v-icon size="22">
              {{ icons.mdiChevronRight }}
            </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <!-- <router-link
            :to="{ name: 'print' }"
            target="_blank"
          >

          </router-link> -->
          <v-btn
            v-if="recordDetail.baseInfo.detail.status === 1 || recordDetail.baseInfo.detail.status == 3"
            color="primary"
            class="mr-5"
            @click="toPrint"
          >
            打印
          </v-btn>
        </div>

        <v-card-text style="background-color: #f4f5fa; height: 90%; padding-top: 20px" class="overflow-auto">
          <v-card>
            <v-card-title>
              <p class="text-h6">基本信息</p>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="4">
                  {{ recordDetail.baseInfo.isIn ? '入' : '出' }}库类型：{{ recordDetail.baseInfo.detail.typeName }}
                </v-col>
                <v-col md="4"> 创建时间：{{ recordDetail.baseInfo.detail.createdTime }} </v-col>
                <v-col md="4">
                  创建人：{{
                    !!orderDialogItem && !!orderDialogItem.sourceOrderNo && !!orderDialogItem.clientName
                      ? orderDialogItem.clientName
                      : recordDetail.baseInfo.detail.creator
                  }}
                </v-col>

                <v-col v-if="recordDetail.baseInfo.detail.sourceOrderNo !== null" md="4"> 来源单据：订单 </v-col>
                <v-col v-if="recordDetail.baseInfo.detail.sourceMaterialId !== null" md="4"> 来源单据：领料单 </v-col>
                <v-col
                  v-if="recordDetail.baseInfo.detail.sourceOrderNo !== null && $can('stock_order', 'stock')"
                  md="4"
                >
                  来源单号：
                  <a href="javascript:void(0)" @click="toOrder(recordDetail.baseInfo.detail.sourceOrderNo)">
                    <span>{{ recordDetail.baseInfo.detail.sourceOrderNo }}</span>
                  </a>
                </v-col>
                <v-col
                  v-if="recordDetail.baseInfo.detail.sourceMaterialId !== null && $can('stock_bill', 'stock')"
                  md="4"
                >
                  领料单号：
                  <a href="javascript:void(0)" @click="toBill(recordDetail.baseInfo.detail.sourceMaterialId)">
                    <span>{{ recordDetail.baseInfo.detail.sourceMaterialId }}</span>
                  </a>
                </v-col>
                <v-col md="4">
                  领料状态：
                  <v-chip
                    :color="getOrderStatusColor(recordDetail.baseInfo.detail.status)"
                    small
                    class="font-weight-semibold text-capitalize"
                  >
                    {{ getOrderStatusByValue(recordDetail.baseInfo.detail.status) }}
                  </v-chip>
                </v-col>
                <v-col md="4"> 审核人：{{ recordDetail.baseInfo.detail.handleUsername }} </v-col>
                <v-col md="4"> 审核时间：{{ recordDetail.baseInfo.detail.handleTime }} </v-col>
                <v-col v-if="model === 0" md="4">
                  客户：{{
                    !!recordDetail.orderDetail
                      ? recordDetail.orderDetail.addressDTO.receiverName
                      : recordDetail.baseInfo.detail.client
                  }}
                </v-col>
                <v-col v-if="model === 0" md="8">
                  发往物流：{{
                    !!recordDetail.orderDetail
                      ? recordDetail.orderDetail.addressDTO.receiverAddr +
                        recordDetail.orderDetail.addressDTO.receiverAddrDetail
                      : recordDetail.baseInfo.detail.receiverAddrDetail || ''
                  }}
                </v-col>
                <v-col
                  v-if="recordDetail.baseInfo.detail.status == 1 || recordDetail.baseInfo.detail.status == 3"
                  md="4"
                >
                  打印状态：
                  <v-badge
                    v-if="recordDetail.baseInfo.detail.printCount"
                    :content="recordDetail.baseInfo.detail.printCount"
                    inline
                    color="info"
                  >
                    <v-chip color="success" small class="font-weight-semibold text-capitalize"> 已打印 </v-chip>
                  </v-badge>
                  <v-chip v-else color="danger" small class="font-weight-semibold text-capitalize"> 未打印 </v-chip>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12"> 备注：{{ recordDetail.baseInfo.detail.remark }} </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card v-if="recordDetailDialog" class="mt-10">
            <v-card-title>
              <p class="text-h6">
                {{ model === 0 ? '配货单详情' : '入库单详情' }}
              </p>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :footer-props="{
                  itemsPerPageAllText: '全部',
                  itemsPerPageText: '每页显示 ',
                  showCurrentPage: true,
                }"
                disable-filtering
                disable-sort
                :headers="getDetailHeader()"
                :items="recordDetails"
                :items-per-page="detailPages.itemsPerPage"
                :server-items-length="detailPages.serverItemsLength"
                no-data-text="无数据"
                @pagination="loadDetailPage"
              >
                <template #[`item.productName`]="{ item }">
                  <v-row>
                    <v-col cols="auto">
                      <v-avatar size="33" @click.stop="previewImg(item.skuProductImg)">
                        <img :src="item.skuProductImg" alt="John" />
                      </v-avatar>
                    </v-col>
                    <v-col class="pl-0">
                      <span class="text-subtitle-2">{{ item.productName }} </span><br />
                      <span v-if="!item.isSingle" class="text-caption">{{ item.title }}</span>
                    </v-col>
                  </v-row>
                </template>
                <template #[`item.categoryName`]="{ item }">
                  <v-row>
                    <v-col class="pl-0">
                      <span class="text-subtitle-2">{{ item.categoryName }} </span><br />
                      <span class="text-caption">{{ item.warehouseName }}</span>
                    </v-col>
                  </v-row>
                </template>
                <template #[`item.totalQuantity`]="{ item }">
                  <span v-if="!recordDetail.baseInfo.isIn" style="color: red">
                    {{ convertToBoxAndRemainder(item.totalQuantity, item) }}
                  </span>
                  <span v-else style="color: rgb(95, 157, 3)">
                    {{ convertToBoxAndRemainder(item.totalQuantity, item) }}
                  </span>
                </template>

                <template #[`item.stockNum`]="{ item }">
                  <span>{{ convertToBoxAndRemainder(item.stockNum, item) }}</span>
                </template>

                <template #[`item.quantity`]="{ item }">
                  <span>{{ convertToBoxAndRemainder(item.quantity, item) }}</span>
                </template>

                <template #[`item.unitConversion`]="{ item }">
                  <v-select
                    id="unitId"
                    v-model="item.unitId"
                    dense
                    style="width: 70px"
                    hide-details
                    item-text="unitName"
                    item-value="id"
                    :items="item.unitConversion"
                  ></v-select>
                </template>
                <template #[`item.convertUnit`]="{ item }">
                  <span>{{ convertToBoxAndRemainder(item.quantity, item) }}</span>
                </template>
                <template #[`item.actions`]="{ item }">
                  <v-btn
                    v-if="$can('product_stock_log', 'stock')"
                    text
                    dense
                    x-small
                    color="primary"
                    @click="showStockLog(item.skuId)"
                  >
                    查看库存日志
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>

      <v-dialog v-model="logDialog" scrollable max-width="85%">
        <v-card>
          <v-card-title>
            <p class="text-h6">库存日志</p>
          </v-card-title>
          <v-card-text style="background-color: #f4f5fa; height: 90%; padding-top: 20px" class="overflow-auto">
            <Log :key="skuStock.key" :sku-stock="skuStock" />
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="billDialog" scrollable max-width="85%">
        <v-card>
          <v-card-title>
            <p class="text-h6">来源单据：领料单</p>
          </v-card-title>
          <v-card-text style="background-color: #f4f5fa; height: 90%; padding-top: 20px" class="overflow-auto">
            <BillDetail :key="detail.key" :detail="detail" />
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="orderDialog" scrollable max-width="85%">
        <v-card>
          <v-card-title>
            <p class="text-h6">来源单据：订单</p>
          </v-card-title>
          <v-card-text style="background-color: #f4f5fa; height: 90%; padding-top: 20px" class="overflow-auto">
            <OrderDetail :key="orderDetail.key" :detail="orderDetail" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-navigation-drawer>
    <!-- 取消订单提示Dialog -->
    <div>
      <v-dialog v-model="orderCancelDialog" max-width="500px" persistent>
        <v-card>
          <v-card-title class="headline">提示</v-card-title>
          <v-card-text >
            确认取消订单? 取消后订单不可恢复
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="orderCancelDialog = false">关闭</v-btn>
            <v-btn color="green darken-1" text @click="orderReject()">确认</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>

  </v-card>
</template>

<script>
import {
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiClose,
  mdiArrowLeft,
  mdiArrowRight,
  mdiChevronLeft,
  mdiChevronRight,
} from '@mdi/js'
import StockApi from '@/api/product/stock'
import Log from '@/components/Log'
import BillDetail from '@/components/BillDetail'
import OrderDetail from '@/components/OrderDetail'
import DatePick from '@/components/DatePicker'
import OrderApi from '@/api/order'

export default {
  name: 'MemberLevel',
  components: {
    Log,
    BillDetail,
    OrderDetail,
    DatePick,
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
        mdiArrowLeft,
        mdiArrowRight,
        mdiChevronLeft,
        mdiChevronRight,
      },
      editDialog: false,
      deleteDialog: false,
      rules: {
        required: [v => !!v || '该字段不能为空'],
      },
      loadings: {
        submitLoading: false,
        tableloading: false,
      },
      stockRecords: [],
      stockTypes: [],
      inSidestockTypes: [],
      outSidestockTypes: [],
      pages: {
        page: 1,
        itemsPerPage: 15,
        serverItemsLength: 0,
        pages: 0,
      },
      skuPages: {
        page: 1,
        itemsPerPage: 15,
        serverItemsLength: 0,
        pages: 0,
      },
      editedItem: {},
      editedIndex: -1,
      defaultItem: {
        id: undefined,
        levelName: '',
        discount: '',
      },
      search: {},
      model: 0,
      choiceOutType: {},
      choiceInType: {},
      baseInfo: {},
      skuSearch: {
        selectOrderType: 0,
      },
      stocks: [],
      categorys: [],
      saleTypes: [
        {
          name: '对外销售',
          value: 'OUTSIDE_SALE',
        },
        {
          name: '对内销售',
          value: 'INSIDE_SALE',
        },
      ],
      skuTab: 1,
      recordQuantity: 0,
      allQuantity: 0,
      skus: [],
      records: [],
      recordDetailDialog: false,
      recordDetail: {
        baseInfo: {
          detail: {},
        },
        orderDetail: null,
        data: {},
      },
      recordDetails: [],
      detailPages: {
        page: 1,
        itemsPerPage: 15,
        serverItemsLength: 0,
        pages: 0,
      },
      inventoryLogDialog: false,
      warehouses: [],
      commitFlag: false,
      skuStock: {
        key: 0,
      },
      logDialog: false,
      billDialog: false,
      detail: {
        key: 0,
      },
      orderDetail: {
        key: 0,
      },
      orderDialog: false,
      orderDialogItem: {},
      currentTab: 0,
      stockCount: {},
      statusList: [
        { text: '待审核', color: 'danger', value: 0 },

        // { text: '已通过', color: 'success', value: 1 },
        { text: '待处理', color: 'warning', value: 3 },
        { text: '已完成', color: 'success', value: 1 },
        { text: '已拒绝 ', color: '', value: 2 },
      ],
      status: 0,
      previewImgUrl: '',
      previewImgDialog: false,
      toPage: null,
      toSkuPage: null,
      orderTypes: [
        {
          text: '按商品分类排序',
          value: 0,
        },
        {
          text: '按库存数量升序',
          value: 1,
        },
        {
          text: '按库存数量降序',
          value: 2,
        },
      ],
      cacheWaitConfirmCount: 0,
      cacheWaitOutStockCount: 0,
      speechPlugin: null, // 语音合成插件
      orderCancelDialog: false,
      currWaitCancelOrder:{},
    }
  },
  mounted() {
    if (this.$route.query.model) {
      this.editItem(parseInt(this.$route.query.model, 0))
    }

    if ('speechSynthesis' in window) {
      // 初始化插件
      this.initSpeechPlugin()

      // 创建定时器，每10s刷新一次页面
      this.broadcastInterval = setInterval(() => {
        this.loadPage({ page: this.pages.page, itemsPerPage: this.pages.itemsPerPage })

        // console.log('刷新页面')
      }, 30 * 1000)
    } else {
      // 浏览器不支持语音合成
      this.$toast.error('当前浏览器不支持语音播报,请使用Edge、Chrome等主流浏览器')
    }
  },
  beforeDestroy() {
    // console.log('销毁定时器')
    clearInterval(this.broadcastInterval)
    window.speechSynthesis.cancel()
    this.speechPlugin = null
  },
  methods: {
    // 原: 0 待审核，1 已通过，2 全部
    // 新: 0 待审核，1换3 待出库，2换1 已出库，3换2 全部(这样就不用修改原数据的状态)
    reloadTab() {
      // if (this.currentTab === 2) {
      //   this.status = undefined
      // } else if (this.currentTab === 1) {
      //   this.status = 1
      // } else if (this.currentTab === 0) {
      //   this.status = 0
      // }
      if (this.currentTab === 3) {
        this.status = undefined
      } else if (this.currentTab === 1) {
        this.status = 3
      } else if (this.currentTab === 0) {
        this.status = 0
      } else if (this.currentTab === 2) {
        this.status = 1
      }
      this.toSearch()
    },
    getHeader() {
      const baseHeader = [
        // { text: 'ID', sortable: false, value: 'id' }, ID
        { text: '单据编号', sortable: false, value: 'stockNumber' },
        { text: '收件客户', value: 'receiverinfo' },
        { text: '类型', value: 'type' },
        { text: '操作人', value: 'creator' },
        { text: '申请时间', value: 'createdTime' },
        { text: '状态', value: 'status' },
      ]
      if (this.status == 1 || this.status == 3) {
        baseHeader.push({ text: '打印状态', value: 'printCount' })
      }
      if (this.$can('product_stock_detail', 'stock')) {
        baseHeader.push({ text: '操作', value: 'actions' })
      }

      return baseHeader
    },
    toSearch() {
      this.pages.page = 1
      this.loadPage({ page: 1, itemsPerPage: this.pages.itemsPerPage })
    },
    resetSearch() {
      this.$refs.searchForm.reset()
      this.search.startTime = ''
      this.search.endTime = ''
      this.toSearch()
    },
    changePage(modifyPage) {
      if (modifyPage > this.pages.pages) {
        this.$toast.error('超过最大总页数')
        return
      }
      this.pages.page = parseInt(modifyPage)
    },
    loadPage({ page, itemsPerPage }) {
      this.loadings.tableloading = true
      let startTime = ''
      if (this.search.startTime) {
        startTime = `${this.search.startTime} 00:00:00`
      }
      let endTime = ''
      if (this.search.endTime) {
        endTime = `${this.search.endTime} 23:59:59`
      }
      const searchData = {
        rows: itemsPerPage,
        page,
        startTime,
        endTime,
        status: this.status,
        typeId: this.search.typeId,
        id: this.search.id,
      }

      StockApi.getPages(searchData)
        .then(response => {
          this.loadings.tableloading = false
          const { data, types, countDTO } = response.data.data

          this.stockRecords = data.records
          if (data.size === -1) {
            this.pages.serverItemsLength = this.stockRecords.length
          } else {
            this.pages.serverItemsLength = data.total
          }
          this.pages.pages = data.pages
          this.stockCount = countDTO
          this.stockTypes = types
          this.inSidestockTypes = types.filter(item => item.type === 'IN_WAREHOUSE')
          this.outSidestockTypes = types.filter(item => item.type === 'OUT_WAREHOUSE')

          // 获取到待审核记录和待处理记录的历史数量。
          this.cacheWaitConfirmCount = localStorage.getItem('StockCacheWaitConfirmCount')
            ? parseInt(localStorage.getItem('StockCacheWaitConfirmCount'), 0)
            : 0
          this.cacheWaitOutStockCount = localStorage.getItem('StockCacheWaitOutStockCount')
            ? parseInt(localStorage.getItem('StockCacheWaitOutStockCount'), 0)
            : 0

          // 有新记录进行语音播报  待审核记录
          if (this.cacheWaitConfirmCount >= 0 && this.cacheWaitConfirmCount < countDTO.waitConfirmCount) {
            this.audioBroadcast()
          } else if (this.cacheWaitOutStockCount >= 0 && this.cacheWaitOutStockCount < countDTO.waitOutStockCount) {
            // 待处理记录
            this.audioBroadcast()
          }

          // 重新存入数据
          localStorage.setItem('StockCacheWaitConfirmCount', countDTO.waitConfirmCount)
          localStorage.setItem('StockCacheWaitOutStockCount', countDTO.waitOutStockCount)
        })
        .catch(error => {
          this.loadings.tableloading = false
        })
    },
    getSkuHeader() {
      const baseHeader = [
        // { text: 'ID', sortable: false, value: 'skuId' },
        { text: '商品名称', value: 'productName' },
        { text: '所属分类/仓库', value: 'categoryName' },
        { text: '包装规格', value: 'unit' },
        { text: '总库存', value: 'stockNum' },
      ]
      if (this.model === 0) {
        baseHeader.push({ text: '配货数量', value: 'quantity' })
        baseHeader.push({ text: '配货后库存', value: 'totalQuantity' })
      } else {
        baseHeader.push({ text: '入库数量', value: 'quantity' })
        baseHeader.push({ text: '入库后库存', value: 'totalQuantity' })
      }

      return baseHeader
    },

    getDetailHeader() {
      const baseHeader = [
        // { text: 'ID', sortable: false, value: 'skuId' },
        { text: '商品名称', value: 'productName' },
        { text: '所属分类/仓库', value: 'categoryName' },
        { text: '包装规格', value: 'unitConversion' },
        { text: '总库存', value: 'stockNum' },
      ]
      if (this.model === 0) {
        baseHeader.push({ text: '配货数量', value: 'quantity' })
        baseHeader.push({ text: '配货后库存', value: 'totalQuantity' })
      } else {
        baseHeader.push({ text: '入库数量', value: 'quantity' })
        baseHeader.push({ text: '入库后库存', value: 'totalQuantity' })
      }

      if (this.$can('product_stock_log', 'stock')) {
        baseHeader.push({ text: '操作', value: 'actions' })
      }

      return baseHeader
    },
    toSkuSearch() {
      this.toSkuPage = null
      this.skuPages.page = 1
      this.loadSkuPage({ page: 1, itemsPerPage: this.skuPages.itemsPerPage })
    },
    resetSkuSearch() {
      this.$refs.searchSkuForm.reset()
      this.$nextTick(() => {
        this.skuSearch.selectOrderType = 0
      })
      this.toSkuSearch()
    },
    changeSkuPage(modifyPage) {
      if (modifyPage > this.skuPages.pages) {
        this.$toast.error('超过最大总页数')
        return
      }
      this.skuPages.page = parseInt(modifyPage)
    },
    loadSkuPage({ page, itemsPerPage }) {
      let rows = itemsPerPage
      if (!rows) {
        rows = this.skuPages.itemsPerPage
      }
      const searchData = {
        rows,
        page,
        categoryId: this.skuSearch.categoryId,
        warehouseId: this.skuSearch.warehouseId,
        name: this.skuSearch.name,
        orderType: this.skuSearch.selectOrderType || 0,
      }
      if (this.skuSearch.saleType) {
        const type = this.skuSearch.saleType
        if (type === 'OUTSIDE_SALE') {
          searchData.isOutsideSale = true
        } else {
          searchData.isInsideSale = true
        }
      }
      if (this.$can('product_stock_in_out_page', 'stock')) {
        StockApi.loadData(searchData).then(response => {
          const { data, searchVo } = response.data.data

          this.skus = data.records
          this.skus.forEach(sku => {
            sku.unitId = sku.unitConversion[0].id
          })
          if (this.records.length > 0) {
            // 根据skuId判断我这个records是否在data.records中，如果在就需要替换里面的对象
            this.records.forEach(record => {
              const index = data.records.findIndex(item => item.skuId === record.skuId)
              if (index !== -1) {
                data.records[index] = record
                data.records[index].unitId = record.unitId
              }
            })
          }

          if (data.size === -1) {
            this.skuPages.serverItemsLength = this.skus.length
          } else {
            this.skuPages.serverItemsLength = data.total
          }
          this.allQuantity = data.total
          this.skuPages.pages = data.pages
          const { categorys, warehouses } = searchVo

          categorys.forEach(category => {
            if (category.parentId !== 0) {
              category.name = `\xa0\xa0${category.name}`
            }
          })
          this.categorys = categorys
          this.warehouses = warehouses
        })
      }
    },
    editItem(item) {
      this.model = item
      this.records = []
      this.skuTab = 1
      this.recordQuantity = 0
      this.baseInfo.remark = ''
      this.baseInfo.receiverAddrDetail = ''
      this.baseInfo.client = ''
      this.toSkuPage = null
      this.skuPages.page = 1
      this.$refs.searchSkuForm.reset()
      this.$nextTick(() => {
        this.skuSearch.selectOrderType = 0
        this.loadSkuPage({ page: 1, itemsPerPage: this.skuPages.itemsPerPage })
      })
      this.commitFlag = false
      this.editDialog = true
    },
    addRecord(item) {
      if (item.quantity === null || item.quantity === undefined || item.quantity === 0 || item.quantity === '') {
        // 从records 中移除
        this.records = this.records.filter(record => record.skuId !== item.skuId)
        this.recordQuantity -= 1
      } else {
        const { unitId } = item
        const { unitConversion } = item
        const selectedUnit = unitConversion.find(unit => unit.id === unitId)
        if (this.model === 0) {
          item.totalQuantity =
            parseInt(item.stockNum, 0) - parseInt(item.quantity, 0) * parseInt(selectedUnit.unitQuantity, 0)
        } else {
          item.totalQuantity =
            parseInt(item.stockNum, 0) + parseInt(item.quantity, 0) * parseInt(selectedUnit.unitQuantity, 0)
        }
        const record = this.records.find(record => record.skuId === item.skuId)
        if (record) {
          record.quantity = item.quantity
        } else {
          this.records.push(item)
          this.recordQuantity += 1
        }
      }
    },
    handleFormSubmit() {
      if (this.model === 0 && this.baseInfo.client === '') {
        this.$toast.warning('请填写客户信息')

        return
      }

      if (this.records.length === 0) {
        this.$toast.warning('请添加出入库记录')

        return
      }

      if (this.skuTab !== 0) {
        this.$toast.warning('请先确认出入库记录和出入库类型，在进行保存')
        this.skuTab = 0

        return
      }

      if (this.commitFlag) {
        return
      }

      let typeId = 0
      if (this.model === 0) {
        typeId = this.choiceOutType.id
      }
      if (this.model === 1) {
        typeId = this.choiceInType.id
      }
      const userStr = localStorage.getItem('userData')
      const user = JSON.parse(userStr)
      let creator = 'system'
      if (user) {
        creator = user.fullName
      }
      const saveData = {
        model: this.model,
        typeId,
        creator,
        remark: this.baseInfo.remark,
        client: this.baseInfo.client,
        receiverAddrDetail: this.baseInfo.receiverAddrDetail,
        records: this.records,
      }
      this.commitFlag = true

      StockApi.addWarehouseRecord(saveData).then(res => {
        this.$toast.success('添加成功')
        this.loadPage({ page: 1, itemsPerPage: this.pages.itemsPerPage })
        this.commitFlag = false
        this.editDialog = false
      })
    },
    showDetail(id) {
      this.recordDetailId = id
      this.orderDialogItem = this.stockRecords.find(item => item.id === id)
      this.loadDetailPage({ page: 1, itemsPerPage: this.detailPages.itemsPerPage })
    },
    loadDetailPage({ page, itemsPerPage }) {
      const searchData = {
        rows: itemsPerPage,
        page,
        id: this.recordDetailId,
        typeId: this.search.typeId,
        status: this.status,
        searchValue: this.search.id,
        orderNo: this.orderDialogItem.sourceOrderNo ? this.orderDialogItem.sourceOrderNo : null,
      }
      StockApi.getRecordDetail(searchData).then(res => {
        this.recordDetail = res.data.data
        this.recordDetails = this.recordDetail.data.records
        if (this.recordDetail.data.size === -1) {
          this.detailPages.serverItemsLength = this.recordDetails.length
        } else {
          this.detailPages.serverItemsLength = this.recordDetail.data.total
        }
        if (this.recordDetail.baseInfo.isIn) {
          this.model = 1
        } else {
          this.model = 0
        }

        // 遍历recordDetails根据productName中文进行排序
        this.recordDetails.sort((a, b) => {
          return a.productName.localeCompare(b.productName, 'zh')
        })
      })
      this.recordDetailDialog = true
    },
    showStockLog(skuId) {
      this.skuStock.skuId = skuId
      this.skuStock.key += 1
      this.logDialog = true
    },
    toSaleList() {
      this.search.typeId = 1
      this.loadPage({ page: 1, itemsPerPage: this.pages.itemsPerPage })
    },
    toOrder(id) {
      this.orderDetail.id = id
      this.orderDetail.key += 1
      this.orderDialog = true
    },
    toBill(id) {
      this.detail.id = id
      this.detail.key += 1
      this.billDialog = true
    },
    getOrderStatusColor(value) {
      const status = this.statusList.find(item => item.value === value)

      return status ? status.color : ''
    },
    getOrderStatusByValue(value) {
      const status = this.statusList.find(item => item.value === value)

      return status ? status.text : ''
    },
    toggleStatus(stockId, status) {
      const userDataStr = localStorage.getItem('userData')
      const userData = JSON.parse(userDataStr)
      const { id } = userData
      StockApi.toggleStatus(stockId, status, id).then(res => {
        this.$toast.success('操作成功')

        this.loadPage(this.pages)
      })
    },
    previewImg(url) {
      this.previewImgUrl = url
      this.previewImgDialog = true
    },
    convertToBoxAndRemainder(totalQuantity, item) {
      const { unitId } = item
      const { unitConversion } = item

      // 根据unitId找到对应的单位
      const selectedUnit = unitConversion.find(unit => unit.id === unitId)

      // 如果isBase为true，则直接返回基准单位的数量
      if (selectedUnit.isBase) {
        return `${totalQuantity}${selectedUnit.unitName}`
      }

      // 计算整箱数和尾数
      const boxes = Math.floor(totalQuantity / selectedUnit.unitQuantity)
      const remainder = totalQuantity % selectedUnit.unitQuantity

      // 构建结果字符串
      let result = ''

      // 如果没有整箱，则只显示尾数
      if (boxes === 0) {
        result = `0箱+${remainder}个尾数`
      } else {
        // 否则显示箱数和尾数
        result = `${boxes}箱`

        // 如果有尾数，则添加尾数部分
        if (remainder > 0) {
          result += `+${remainder}个尾数`
        }
      }

      return result
    },
    changeUnit(item) {
      const { unitId } = item
      const { unitConversion } = item
      const selectedUnit = unitConversion.find(unit => unit.id === unitId)
      if (this.model === 0) {
        item.totalQuantity =
          parseInt(item.stockNum, 0) - parseInt(item.quantity, 0) * parseInt(selectedUnit.unitQuantity, 0)
      } else {
        item.totalQuantity =
          parseInt(item.stockNum, 0) + parseInt(item.quantity, 0) * parseInt(selectedUnit.unitQuantity, 0)
      }
      const record = this.records.find(record => record.skuId === item.skuId)
      if (record) {
        record.quantity = item.quantity
      } else {
        this.records.push(item)
        this.recordQuantity += 1
      }
    },
    toPrint() {
      // this.$router.push({ name: 'print' }, () => window.open(this.$route.href, '_blank'))

      const routeUrl = this.$router.resolve({
        path: '/print',

        query: { id: 96 },
      })

      // 执行跳转打印界面后，一秒后再执行请求刷新数据接口，确保数据为最新数据状态
      setTimeout(() => {
        this.toSearch()
        this.recordDetailDialog = false
      }, 1000)
      if (!!this.orderDialogItem && !!this.orderDialogItem.sourceOrderNo && !!this.orderDialogItem.clientName) {
        this.$set(this.recordDetail.baseInfo.detail, 'creator', this.orderDialogItem.clientName)
      }
      localStorage.setItem('printData', JSON.stringify(this.recordDetail))

      window.open(routeUrl.href, '_blank')
    },

    // 有新订单时进行语音播报
    audioBroadcast() {
      // console.log('有新出入库时进行语音播报')
      // 播放语音
      window.speechSynthesis.speak(this.speechPlugin)
    },

    // 初始化语音合成插件
    initSpeechPlugin() {
      this.speechPlugin = new SpeechSynthesisUtterance()

      // 播报完成回调函数
      this.speechPlugin.onend = function (event) {
        // console.log(event);
      }

      // 设置音量为0.8
      this.speechPlugin.volume = 0.8

      // 设置语速为1.5倍
      this.speechPlugin.rate = 1

      // 设置语言为简体中文  en-US
      this.speechPlugin.lang = 'zh-CN'

      // 设置语音内容
      this.speechPlugin.text = '您有新的出入库单据,请及时处理'
    },
    updateOptions(options) {
      this.pages.page = options.page
      this.pages.itemsPerPage = options.itemsPerPage
    },
    // 打开取消dialog提示
    openCancelOrderDialog(order, status) {
      this.orderCancelDialog = true
      this.currWaitCancelOrder = order
    },
    // 未发货前拒绝订单
    orderReject(){
      let stockId = this.currWaitCancelOrder.id;
      let orderNo = this.currWaitCancelOrder.sourceOrderNo;
      this.getCurrentUserName()
      OrderApi.toggleOrderStatus(orderNo, 7, this.getCurrentUserName()).then(res => {
        this.toggleStatus(stockId, 2);
        this.orderCancelDialog = false;
      }).catch(err => {
        this.orderCancelDialog = false;
        console.log(err);
      })
    },
    getCurrentUserName() {
      const userDataStr = localStorage.getItem('userData')
      const userData = JSON.parse(userDataStr)
      const { fullName } = userData
      return fullName
    },

  },
}
</script>

<style scoped></style>
