<template>
  <div>
    <v-row
      style="background-color: #F4F5FA; height: 90%; padding-top: 20px;"
      class="overflow-auto"
    >
      <v-col
        cols="12"
        md="4"
      >
        <v-card
          class="pt-2 ml-5 overflow-auto overflow-auto"
        >
          <v-card-title class="justify-center flex-column">
            <v-avatar
              color="primary"
              class="v-avatar-light-bg primary--text"
              size="120"
              rounded
            >
              <v-img
                :src="require('@/assets/images/avatars/1.png')"
              ></v-img>
            </v-avatar>
            <h4 class="mb-2 mt-3">
              {{ userInfo.userName }}
            </h4>

            <span class="mb-2 text-caption">{{ userInfo.memberName }}</span>
          </v-card-title>

          <v-card-text>
            <p class="text-h6">
              订货信息
            </p>

            <v-divider></v-divider>

            <v-list>
              <v-list-item
                dense
                class="px-0 mb-n2"
              >
                <span class="font-weight-medium text-no-wrap me-2">下单时间</span>
                <span class="text--secondary">{{ order.createdTime }}</span>
              </v-list-item>

              <v-list-item
                dense
                class="px-0 mb-n2"
              >
                <span class="font-weight-medium me-2">订单编号</span>
                <span class="text--secondary text-capitalize">{{ order.orderNo }}</span>
              </v-list-item>
            </v-list>
          </v-card-text>

          <v-card-text>
            <p class="text-h6">
              订货单位
            </p>

            <v-divider></v-divider>

            <v-list>
              <v-list-item
                dense
                class="px-0 mb-n2"
              >
                <span class="font-weight-medium me-2">单位名称</span>
                <span class="text--secondary">{{ companyDetail.companyName }}</span>
              </v-list-item>

              <v-list-item
                dense
                class="px-0 mb-n2"
              >
                <span class="font-weight-medium text-no-wrap me-2">收件人</span>
                <span class="text--secondary">{{ companyDetail.receiverName }}</span>
              </v-list-item>

              <v-list-item
                dense
                class="px-0 mb-n2"
              >
                <span class="font-weight-medium me-2">收货地址</span>
                <span class="text--secondary text-capitalize">{{ companyDetail.receiverAddr }}{{ companyDetail.receiverAddrDetail }}</span>
              </v-list-item>

              <v-list-item
                dense
                class="px-0 mb-n2"
              >
                <span class="font-weight-medium me-2">发票类型</span>
                <span class="text--secondary text-capitalize">{{ companyDetail.invoiceType === 0 ? '普通发票': '增值税发票' }}</span>
              </v-list-item>
              <v-list-item
                dense
                class="px-0 mb-n2"
              >
                <span class="font-weight-medium me-2">发票抬头</span>
                <span class="text--secondary text-capitalize">{{ companyDetail.companyName }}</span>
              </v-list-item>

              <v-list-item
                dense
                class="px-0 mb-n2"
              >
                <span class="font-weight-medium me-2">单位税号</span>
                <span class="text--secondary text-capitalize">{{ companyDetail.invoiceNo }}</span>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="8"
      >
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                <p class="text-h6">
                  商品列表
                </p>
              </v-card-title>
              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          商品描述
                        </th>
                        <th class="text-left">
                          数量
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in orderDetails"
                        :key="index"
                      >
                        <td>
                          <v-row>
                            <v-col
                              class="pa-2 align-self-center"
                              cols="auto"
                            >
                              <v-avatar size="33">
                                <img
                                  :src="item.productImg"
                                >
                              </v-avatar>
                            </v-col>
                            <v-col class="pl-0">
                              <span class="text-subtitle-2">{{ item.productName }}  </span><br>
                              <span
                                class="text-caption"
                              >{{ item.productSkuTitle }}</span>
                            </v-col>
                          </v-row>
                        </td>
                        <td>{{ item.productSkuQuantity }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card

              class="overflow-auto"
            >
              <v-card-title>
                <p class="text-h6">
                  订单详情
                </p>
              </v-card-title>

              <v-card-text>
                <!-- 给个timeline  左边显示 并且带图标 -->
                <v-timeline
                  dense
                  class="timeline-custom-dense timeline-custom-dots"
                >
                  <!-- Timeline Item: Flight -->
                  <v-timeline-item
                    v-for="(record, index) in orderRecords"
                    :key="index"
                    color="transparent"
                  >
                    <template #icon>
                      <v-avatar
                        color="primary"
                        class="v-avatar-light-bg primary--text"
                        size="40"
                      >
                        <v-icon
                          size="24"
                          color="primary"
                        >
                          {{ icons.mdiFileDocumentOutline }}
                        </v-icon>
                      </v-avatar>
                    </template>
                    <!-- Header -->
                    <div class="d-flex justify-space-between">
                      <p class="mb-1 text-caption text--primary">
                        {{ record.createdTime }}
                      </p>
                    </div>

                    <!-- Content -->
                    <p class="mb-1">
                      <span class=" font-weight-semibold">{{ record.createdBy }} 修改订单状态
                        <span style="color: rgb(0, 187, 255)">{{ record.statusDesc }}</span> </span>
                    </p>
                  </v-timeline-item>

                  <v-timeline-item
                    color="transparent"
                  >
                    <template #icon>
                      <v-avatar
                        color="primary"
                        class="v-avatar-light-bg primary--text"
                        size="40"
                      >
                        <v-icon
                          size="24"
                          color="primary"
                        >
                          {{ icons.mdiFileDocumentOutline }}
                        </v-icon>
                      </v-avatar>
                    </template>
                    <!-- Header -->
                    <div class="d-flex justify-space-between">
                      <p class="mb-1 text-caption text--primary">
                        {{ order.createdTime }}
                      </p>
                    </div>

                    <!-- Content -->
                    <p class="mb-1">
                      <span class=" font-weight-semibold">{{ userInfo.userName }} 创建了订单</span>
                    </p>
                  </v-timeline-item>
                </v-timeline>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiFileDocumentOutline,
} from '@mdi/js'
import OrderApi from '@/api/order'

export default {
  name: 'OrderDetail',
  components: {
  },
  props: {
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      icons: {
        mdiFileDocumentOutline,
      },
      order: {},
      orderDetails: [],
      orderRecords: [],
      companyDetail: {},
      userInfo: {
        memberName: '',
        userName: '',
      },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    initOrderDetail() {
      this.companyDetail = {}
      this.order = {}
      this.orderRecords = []
      this.orderDetails = []
      this.userInfo.memberName = ''
      this.userInfo.userName = ''
    },
    loadData() {
      this.initOrderDetail()

      OrderApi.getOrderDetail(this.detail.id).then(res => {
        const {
          detailResponse, memberName, userName,
        } = res.data.data
        const {
          orderDTO, recordDTOS, detailDTOS, addressDTO,
        } = detailResponse
        this.companyDetail = addressDTO
        this.order = orderDTO
        this.orderRecords = recordDTOS
        this.orderDetails = detailDTOS
        this.userInfo.memberName = memberName
        this.userInfo.userName = userName
      })
    },
    getCurrentUserName() {
      const userDataStr = localStorage.getItem('userData')
      const userData = JSON.parse(userDataStr)
      const { fullName } = userData

      return fullName
    },
  },
}
</script>

<style>
.fixed-top {
  position: fixed;
  top: 0;
  /* width: 100%; */
}
</style>
